import React from "react";

const Login = () => {
    return (
        <div className="loginsignup">
            <div className="loginsignup-container">
                <h1>Sign Up</h1>
                <div className="loginsetup-fields">
                    <input type="text" placeholder="Nombre" />
                    inp
                </div>
            </div>
        </div>
    )
}

export default Login